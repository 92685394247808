const Compatibility = () => {
  if (typeof window !== "undefined") {
    window.location.replace(
      "https://support.gaston.tel/portal/kb/articles/quels-operateurs-gaston-supporte-t-il"
    );
  }
  return null;
};

export default Compatibility;
